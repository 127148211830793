import "./styles.css";
import { MicrobitWebBluetoothConnection } from "@microbit/microbit-connection";

import PartySocket from "partysocket";
import { people } from "./constants";

declare const PARTYKIT_HOST: string;

let whoami = "matt";

const microbitConnection = new MicrobitWebBluetoothConnection();
document.querySelector("button")!.onclick = async () => {
  await microbitConnection.connect();
  microbitConnection.addEventListener("uartdata", (e) => {
    if (new TextDecoder().decode(e.value).trim() === "snowball") {
      console.log("Sending a snowball!");
      partySocket.send(JSON.stringify({ type: "snowball", source: whoami }));
    }
  });
};

// A PartySocket is like a WebSocket, except it's a bit more magical.
// It handles reconnection logic, buffering messages while it's offline, and more.
const partySocket = new PartySocket({
  host: PARTYKIT_HOST,
  room: "my-new-room",
});

// You can even start sending messages before the connection is open!
partySocket.addEventListener("message", (event) => {
  console.log(`Received -> ${event.data}`);
  const json = JSON.parse(event.data);
  switch (event.data.type) {
    // Maybe the person throwing could become a target? But then you'd need > 1 target at once. So who would a snowball hit?
    case "snowball": {
      const { source, target, hitStatus } = event.data;
      break;
    }
    case "targetChange": {
      const { who } = event.data;
      break;
    }
    default: {
      // Ingore unknown!
    }
  }
});

const updatePerson = (person: { name: string; url: string }) => {
  whoami = person.name;
  const image = document.createElement("img");
  image.src = person.url;
  image.alt = person.name;
  image.style.width = "80px";
  image.style.height = "80px";
  image.style.position = "absolute";
  image.style.top = "10px";
  image.style.right = "10px";
  image.style.borderRadius = "50%";
  document.body.appendChild(image);
  partySocket.send(
    JSON.stringify({
      type: "register",
      name: person.name,
    }),
  );
};

window.onload = () => {
  const dialog = document.querySelector("dialog")!;
  people.forEach((person) => {
    const button = document.createElement("button");
    button.onclick = () => {
      updatePerson(person);
      dialog.close();
    };
    button.style.cursor = "pointer";
    const image = document.createElement("img");
    image.src = person.url;
    image.alt = person.name;
    button.appendChild(image);
    dialog.appendChild(button);
  });
  dialog.showModal();
};
