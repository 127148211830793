export const people = [
  {
    name: "Aimée",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/4e2e6332a243121e300445e123d99371424cc50b-200x200.jpg?bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Anna",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/47083b5387847d280c3ae2b838452f0909b02b5a-1172x1172.jpg?bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Bisi",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/24cf605e6bf83462d51b75721adc0c448e2e51bc-1080x1328.jpg?rect=0,124,1080,1080&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Bláthnaid",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/89a91ee737e209eb54b17487452109b1dfd73ab1-160x285.jpg?rect=0,62,160,160&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Carlos",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/26fa772b44a2786cc3bff0408333bc891d1a4e1a-100x120.jpg?rect=0,10,100,100&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Caroline",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/71637d4eae448614820608eff0e4a28127d5435e-2316x3088.jpg?rect=0,386,2316,2316&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Chloe",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/f2acecd32e175d9f66e7a296d104eac705238cb9-2289x3056.jpg?rect=276,532,1681,1681&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Elaine",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/defc8d82707d75471649b6caadddfe857607a62a-723x777.jpg?rect=0,27,723,723&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Emma M",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/d7eb8218db4feaf472e496df3a58528c6f1c42ea-579x994.jpg?rect=0,194,571,571&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Emma P",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/5cd6d27e817a05e5288d8a1f72a0239a2632ae6f-614x798.jpg?rect=93,128,521,521&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Emma S",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/3ed06300bbc053c48e066795567f493f2d8692e5-100x120.jpg?rect=0,10,100,100&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Gareth",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/d92a44366e1592494fd2f8d273cbf9d577c02c1a-100x120.jpg?rect=0,10,100,100&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Giles",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/a743d2887faaa59e188a3cc1733b546e89348288-907x944.jpg?rect=0,18,907,907&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Grace",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/0b99964684f8255caf4b3645d6afca14e9b5b3a0-2000x2000.jpg?rect=96,0,1809,1809&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Jo I",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/9ea02f54c560d146b6e5f837478404d1a7ce159f-100x178.png?rect=0,39,100,100&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Jo J",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/1270a4dd0d130a3dcf47e00e8b4c815d9a19d75b-1920x2560.jpg?rect=0,320,1920,1920&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Joe",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/2e5b1ea9ce5fa4c549f0dacf93e48ea08812364b-100x120.jpg?rect=0,10,100,100&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "John",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/e63b9ad2d9b878e0f1743fd641999f39ded399fd-2000x1500.jpg?rect=129,0,1500,1500&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Jonny",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/39c467ef7cf611bf02df3b4cb754d85542263711-100x120.png?rect=0,10,100,100&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Jose",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/93945e5985d228799faf62558a82cb722e9ba727-100x120.jpg?rect=0,10,100,100&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Katie",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/60f283034ba33d75fe32c6873905d975e975553d-4000x3200.jpg?rect=500,0,3115,3115&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Kori",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/a2287e9b2d4ad25190a86f64e0c743d020f08505-1030x1030.jpg?bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Limin",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/82e4e83087abb50a93f1c01eaf27927b58a95144-2320x3088.jpg?rect=0,384,2320,2320&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Lorna",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/c7f8b6baf6a7c252732f19dce947a2105d0a38c4-141x154.png?rect=0,6,141,141&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Lucy",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/2148587af0ff2f133b0eed8fd00dff1b2e9687e2-427x640.jpg?rect=0,106,427,427&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Lynn",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/500c82f55075e893db4fd4a4f8fe98bde64e60cd-1932x2576.jpg?rect=0,322,1932,1932&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Magda",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/949caca0ee71f72fe3dc59e44e6d04e7cefc758d-90x120.jpg?rect=0,15,90,90&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Matt",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/1c79ef4a0c6282251d6aa8244fe4ec0398725060-2208x2944.jpg?rect=0,702,2044,2044&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Melanie",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/c3e9bb5a96bc257075b1562a33a48a83407a8199-2316x3088.jpg?rect=0,386,2316,2316&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Rachel",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/3679cdefbf35a14269e5982d04157ddeee08e9d8-1224x1632.png?rect=0,204,1224,1224&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Rhiannon",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/41f69fd0e1a7e01edb6273efd8bccbaa4e087e09-1920x2560.jpg?rect=0,320,1920,1920&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Rob",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/ad0e45015d3ccfea66d88e86f395582ccedd496f-512x512.jpg?bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Roz",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/45f5df085ac9f0a17b2b3b78107889a342e05d75-361x456.png?rect=0,47,361,361&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Sara",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/8a7dc4f0218973ca8005dd9c2a862bd2af38942a-875x878.jpg?rect=0,1,875,875&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
  {
    name: "Waris",
    url: "https://cdn.sanity.io/images/ajwvhvgo/production/b747e92b6aa091d7a8e5461a195cce2b7363356e-100x120.jpg?rect=0,10,100,100&bg=fff&w=280&h=280&q=90&fit=fill&auto=format",
  },
];
